/* b6d37013f2c51c882f853b8e7447d179a2d7dc00
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ContactFragmentFragment = { __typename?: 'new_contact', id: any, emailAddress: string, labels: any, lastInteractedAt: any, avatar?: string | null, displayName?: string | null, favoriteAt?: any | null, notes?: string | null };

export type ContactsSubscriptionVariables = Types.Exact<{
  currentUserEmail?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type ContactsSubscription = { __typename?: 'subscription_root', new_contact: Array<{ __typename?: 'new_contact', id: any, emailAddress: string, labels: any, lastInteractedAt: any, avatar?: string | null, displayName?: string | null, favoriteAt?: any | null, notes?: string | null }> };

export const ContactFragmentFragmentDoc = gql`
    fragment ContactFragment on new_contact {
  id
  emailAddress
  labels
  lastInteractedAt
  avatar
  displayName
  favoriteAt
  notes
}
    `;
export const ContactsDocument = gql`
    subscription Contacts($currentUserEmail: String) {
  new_contact(
    order_by: {lastInteractedAt: desc_nulls_last, favoriteAt: desc_nulls_last}
    where: {_not: {emailAddress: {_eq: $currentUserEmail}}}
  ) {
    ...ContactFragment
  }
}
    ${ContactFragmentFragmentDoc}`;

export function useContactsSubscription<TData = ContactsSubscription>(options: Omit<Urql.UseSubscriptionArgs<ContactsSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<ContactsSubscription, TData>) {
  return Urql.useSubscription<ContactsSubscription, TData, ContactsSubscriptionVariables>({ query: ContactsDocument, ...options }, handler);
};