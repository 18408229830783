import { useUser, useUserEmail } from 'contexts/auth';
import {
  ContactsByFavoriteQuery,
  useContactsByFavoriteQuery,
} from 'graphql/queries/contacts.graphql';
import { atomWithStorage, useAtomValue, useUpdateAtom } from 'jotai/utils';
import { useEffect } from 'react';

export const favoritesAtom = atomWithStorage<
  ContactsByFavoriteQuery['new_contact']
>('favoritesAtom', []);

export default function useFavorites() {
  return useAtomValue(favoritesAtom);
}

export function SyncFavorites(): null {
  const setFavorites = useUpdateAtom(favoritesAtom);
  const user = useUser();
  const userEmail = useUserEmail();
  const pause = user?.didAuth === false;
  const domainEndsWith = `%@${userEmail?.split('@')[1]}`;

  const [queryResults] = useContactsByFavoriteQuery({
    pause,
    requestPolicy: 'cache-and-network',
    variables: {
      domainEndsWith,
    },
  });

  useEffect(() => {
    if (queryResults?.data?.new_contact) {
      setFavorites(queryResults.data.new_contact);
    }
  }, [queryResults?.data?.new_contact, setFavorites]);

  return null;
}
