import { getCalendarType } from 'utils/share';
import { parseISO } from 'date-fns';
import {
  CalendarLinksForUserQuery,
  useCalendarLinksForUserQuery,
} from 'graphql/queries/calendarLink.graphql';
import { atom } from 'jotai';
import { useAtomCallback, useAtomValue } from 'jotai/utils';
import { useCallback, useEffect } from 'react';
import { serverSlotsToFrontendSlots } from '../helpers/transformCalendarLinkHelper';
import {
  CalendarLink,
  calendarLinkIdsAtom,
  calendarLinksFamily,
} from '../calendarLinkAtoms';

export function useCalendarLink(id: string) {
  return useAtomValue(calendarLinksFamily(id));
}

export function useCalendarLinkListIds() {
  return useAtomValue(calendarLinkIdsAtom);
}

export function useRefreshCalendarLinks() {
  const [results, refresh] = useCalendarLinksForUserQuery({
    requestPolicy: 'network-only',
  });

  const updateCalendarLinksFamily = useAtomCallback(
    useCallback((_, set, userCalendarLinks: CalendarLink[]) => {
      const sanitizedLinks = userCalendarLinks
        .filter(
          (shareLink) =>
            (shareLink.type === 'one-off' && shareLink.bookedCount === 0) ||
            shareLink.type === 'recurring'
        )
        .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
      sanitizedLinks.forEach((calendarLink) =>
        set(calendarLinksFamily(calendarLink.id), calendarLink)
      );
      set(
        calendarLinkIdsAtom,
        sanitizedLinks.map((calendarLink) => calendarLink.id)
      );
    }, [])
  );

  useEffect(() => {
    if (!results.fetching && results.data?.shareCalendarLink) {
      updateCalendarLinksFamily(
        results.data?.shareCalendarLink.map(rawCalendarLinkToSharedCalendarLink)
      );
    }
  }, [results.fetching, results.data, updateCalendarLinksFamily]);

  return refresh;
}

export function SyncCalendarLinks() {
  useRefreshCalendarLinks();
  return null;
}

const oneOffUnbookedCalendarLinkIdsAtom = atom((get) => {
  const linkIds = get(calendarLinkIdsAtom);
  return linkIds
    .map((id) => get(calendarLinksFamily(id)))
    .filter(
      (calendarLink): calendarLink is CalendarLink =>
        calendarLink?.type === 'one-off'
    )
    .map((calendarLink) => calendarLink?.id);
});

export function useOneOffUnbookedCalendarLinkIds(): string[] {
  return useAtomValue(oneOffUnbookedCalendarLinkIdsAtom);
}

function rawCalendarLinkToSharedCalendarLink(
  rawCalendarLink: CalendarLinksForUserQuery['shareCalendarLink'][0]
): CalendarLink {
  const payload = rawCalendarLink.payload || {};
  const slots = serverSlotsToFrontendSlots(payload.slots, rawCalendarLink.id);

  return {
    id: rawCalendarLink.id,
    type: getCalendarType({ slots }),
    bookedCount: payload.bookedCount || 0,
    calendarId: payload.calendarId,
    alias: rawCalendarLink.alias || '',
    title: payload.title,
    description: payload.description,
    location: payload.location || '',
    createdAt: parseISO(rawCalendarLink.createdAt),
    updatedAt: parseISO(rawCalendarLink.updatedAt),
    bufferDurationMinutes: payload.bufferDurationMinutes,
    eventMaxDurationMinutes: payload.eventMaxDurationMinutes,
    eventMinDurationMinutes: payload.eventMinDurationMinutes,
    slots,
    status: 'published',
  };
}
