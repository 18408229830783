import {
  CalendarStartsOn_Enum,
  ColorFamily,
  ColorFamilyEnum_Enum,
  DateFormat_Enum,
  VideoProvider_Enum,
} from '@graphql-types@';
import { DateTime } from 'luxon';
import { UserQuery } from 'graphql/queries/user.graphql';
import { getBrowserTimezone } from './time';
import { MEET_LINK_PLACEHOLDER, MEET_LINK_PLACEHOLDER_DRAFT } from './video';

export const TWENTY_FOUR_HOUR_FORMAT = {
  short: 'HH:mm',
  long: 'HH:mm',
};

export const TWELVE_HOUR_FORMAT = {
  long: 'h:mm a',
  short: 'h a',
};

export enum DATE_FORMAT {
  EU = 'dd.MM.yyyy',
  US = 'MM/dd/yyyy',
}

export type UserTimeFormatPreference =
  | typeof TWENTY_FOUR_HOUR_FORMAT
  | typeof TWELVE_HOUR_FORMAT;

export function getTimeFormat(
  isUi24HourClock: boolean
): UserTimeFormatPreference {
  return isUi24HourClock ? TWENTY_FOUR_HOUR_FORMAT : TWELVE_HOUR_FORMAT;
}

export function normalizeEventDuration(
  defaultDuration: number | undefined
): number {
  return defaultDuration === undefined ? 30 : defaultDuration;
}

export function normalizeDateFormat(
  dateFormat?: Nullable<DateFormat_Enum> | undefined
): DATE_FORMAT {
  const savedPreference =
    dateFormat === 'DAY_MONTH' ? DATE_FORMAT.EU : DATE_FORMAT.US;
  if (!savedPreference) return DATE_FORMAT.EU;
  return savedPreference === DATE_FORMAT.EU ? DATE_FORMAT.EU : DATE_FORMAT.US;
}

export function withPreferenceDefaults(
  preferences: PickedPreferences | undefined
) {
  const timezone = preferences?.timezone || getBrowserTimezone();

  let workHoursStartTime = preferences?.workHoursStartTime
    ? DateTime.fromFormat(
        preferences.workHoursStartTime,
        TWENTY_FOUR_HOUR_FORMAT.short
      ).setZone(timezone, { keepLocalTime: true })
    : undefined;

  let workHoursEndTime = preferences?.workHoursEndTime
    ? DateTime.fromFormat(
        preferences.workHoursEndTime,
        TWENTY_FOUR_HOUR_FORMAT.short
      ).setZone(timezone, { keepLocalTime: true })
    : undefined;

  // Automatically fix work hours if needed.
  if (workHoursStartTime != null && workHoursEndTime != null) {
    if (workHoursEndTime?.equals(workHoursStartTime)) {
      workHoursEndTime = workHoursStartTime.plus({ hours: 8 });
    } else if (workHoursEndTime < workHoursStartTime) {
      // If end < start, then swap them.
      const tmp = workHoursEndTime;
      workHoursEndTime = workHoursStartTime;
      workHoursStartTime = tmp;
    }
  }

  return {
    aroundLink: preferences?.aroundLink || undefined,
    zoomLink: preferences?.zoomLink || undefined,
    calendarStartsOn:
      preferences?.calendarStartsOn || CalendarStartsOn_Enum.Monday,
    dateFormat: normalizeDateFormat(preferences?.dateFormat),
    defaultDuration: normalizeEventDuration(preferences?.defaultDuration),
    theme: preferences?.theme || undefined,
    timezone,
    todoPrivacy: preferences?.todoPrivacy || undefined,
    showDoneTodos: !!preferences?.showDoneTodos,
    showArchivedLists: !!preferences?.showArchivedLists,
    ui24HourClock: !!preferences?.ui24HourClock,
    uiHideWeekend: preferences?.uiHideWeekend || undefined,
    videoProvider: preferences?.videoProvider || undefined,
    uiVisibleCalendarsId: preferences?.uiVisibleCalendarsId || undefined,
    defaultEventColor: recastEventColorFamily(preferences?.defaultEventColor),
    workHoursStartTime,
    workHoursEndTime,
    workHoursEnabled: !!preferences?.workHoursEnabled,
    hideDeclinedEvents: preferences?.hideDeclinedEvents ?? false,
  };
}

export type PickedPreferences = UserQuery['new_user'][0]['preferences'];

export type NormalizedPreferences = ReturnType<typeof withPreferenceDefaults>;

export function recastEventColorFamily(
  color?: ColorFamilyEnum_Enum | null
): ColorFamily {
  switch (color) {
    case ColorFamilyEnum_Enum.Blue:
      return ColorFamily.Blue;
    case ColorFamilyEnum_Enum.Green:
      return ColorFamily.Green;
    case ColorFamilyEnum_Enum.Orange:
      return ColorFamily.Orange;
    case ColorFamilyEnum_Enum.Pink:
      return ColorFamily.Pink;
    case ColorFamilyEnum_Enum.Purple:
      return ColorFamily.Purple;
    case ColorFamilyEnum_Enum.Red:
      return ColorFamily.Red;
    case ColorFamilyEnum_Enum.Yellow:
      return ColorFamily.Yellow;
    default:
      return ColorFamily.Gray;
  }
}

export function recastColorFamilyToEnum(
  color?: ColorFamily
): ColorFamilyEnum_Enum {
  switch (color) {
    case ColorFamily.Blue:
      return ColorFamilyEnum_Enum.Blue;
    case ColorFamily.Green:
      return ColorFamilyEnum_Enum.Green;
    case ColorFamily.Orange:
      return ColorFamilyEnum_Enum.Orange;
    case ColorFamily.Pink:
      return ColorFamilyEnum_Enum.Pink;
    case ColorFamily.Purple:
      return ColorFamilyEnum_Enum.Purple;
    case ColorFamily.Red:
      return ColorFamilyEnum_Enum.Red;
    case ColorFamily.Yellow:
      return ColorFamilyEnum_Enum.Yellow;
    default:
      return ColorFamilyEnum_Enum.Gray;
  }
}

export function getDefaultVideoLocation(
  preferences: NormalizedPreferences
): string | null {
  if (
    preferences.videoProvider === VideoProvider_Enum.Zoom &&
    preferences.zoomLink
  ) {
    return preferences.zoomLink;
  }

  if (
    preferences.videoProvider === VideoProvider_Enum.Around &&
    preferences.aroundLink
  ) {
    return preferences.aroundLink;
  }

  if (
    !preferences.videoProvider ||
    preferences.videoProvider === VideoProvider_Enum.None
  ) {
    return '';
  }

  return MEET_LINK_PLACEHOLDER_DRAFT;
}
