import { NewVideoConferenceProvider } from '@graphql-types@';
import { EventName } from 'types/analytics';
import { trackEvent } from './analytics';
import { DEEP_LINK_IFRAME_ID } from './constants';

const getMainZoomChunk = (url: string): string | undefined =>
  url.split('/j/')[1];
const getTrimmedZoomChunk = (chunk?: string) => chunk?.split('#')[0] || '';
const getMeetingId = (chunk: string) => chunk.split('?')[0];
const getPwd = (chunk: string): string | undefined => chunk.split('pwd=')[1];
const openTabFallback = (url: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  link.remove();
};

const SAFARI_REGEX = /^((?!chrome|android).)*safari/i;
// matches http(s)://organization.zoom, http(s)://zoom
export const ZOOM_URL_REGEX = /https?:\/\/(.*.)?zoom/i;
export const NON_ORGANIZATION_ZOOM_URL_REGEX = /https?:\/\/zoom.us/i;
export const AMIE_VIDEO_URL_REGEX = /https?:\/\/(.*.)?amie.so\/v/i;
export const AROUND_URL_REGEX = /https?:\/\/(.*.)?around.co\/r/i;
export const MEET_URL_REGEX = /https?:\/\/meet.google.com/i;
export const MICROSOFT_TEAMS_URL_REGEX = /https?:\/\/teams.microsoft.com/i;

export const MEET_LINK_PLACEHOLDER_DRAFT = 'Google Meet';
export const MEET_LINK_PLACEHOLDER = 'Loading Google Meet link';
export const ZOOM_LINK_PLACEHOLDER = 'Loading Zoom link';
export const AMIE_VIDEO_LINK_PLACEHOLDER = 'Loading Amie Video link';

type HandleVideoClick = {
  event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>;
  url: string;
};

export function isVideoLink(url?: string | null): boolean {
  return !!getVideoProvider(url);
}

export function getVideoProvider(url?: string | null): string | undefined {
  if (!url) return undefined;
  if (AMIE_VIDEO_URL_REGEX.test(url)) return 'Amie Video';
  if (AROUND_URL_REGEX.test(url)) return 'Around';
  if (MEET_URL_REGEX.test(url) || url === MEET_LINK_PLACEHOLDER_DRAFT)
    return 'Google Meet';
  if (ZOOM_URL_REGEX.test(url)) return 'Zoom';
  if (MICROSOFT_TEAMS_URL_REGEX.test(url)) return 'Teams';
  return undefined;
}

export function getVideoProviderEnum(
  location: string | undefined
): NewVideoConferenceProvider | undefined {
  const isGoogleMeet = MEET_LINK_PLACEHOLDER === location;
  const isAround = location && AROUND_URL_REGEX.test(location);
  const isZoom = ZOOM_LINK_PLACEHOLDER === location;
  const isAmieVideo = AMIE_VIDEO_LINK_PLACEHOLDER === location;

  if (isZoom) {
    return NewVideoConferenceProvider.Zoom;
  } else if (isGoogleMeet) {
    return NewVideoConferenceProvider.GoogleMeet;
  } else if (isAround) {
    return NewVideoConferenceProvider.Around;
  } else if (isAmieVideo) {
    // in the future we might need to add Amie Video check too, currently it doesn't need one
    return NewVideoConferenceProvider.None;
  }
  return undefined;
}

export const handleVideoClick = ({ event, url }: HandleVideoClick): void => {
  if (event) event.preventDefault();

  trackEvent(EventName.OpenedVideoConference);

  if (
    MEET_URL_REGEX.test(url) === true ||
    SAFARI_REGEX.test(navigator.userAgent)
  ) {
    // Not a zoom link, or is using Safari.
    return openTabFallback(url);
  }

  const frameLoader = document.getElementById(DEEP_LINK_IFRAME_ID);

  if (AROUND_URL_REGEX.test(url) === true) {
    const linkWithoutProtocol = url.replace(/^https?:\/\//, '');
    const aroundLink = `around://${linkWithoutProtocol}`;

    if (frameLoader instanceof HTMLIFrameElement) {
      frameLoader.src = aroundLink;
    }
  }

  if (NON_ORGANIZATION_ZOOM_URL_REGEX.test(url) === true) {
    const firstChunk = getMainZoomChunk(url);
    const trimmedChunk = getTrimmedZoomChunk(firstChunk);
    const meetingId = getMeetingId(trimmedChunk);
    const pwd = getPwd(trimmedChunk);

    const zoomLink = `zoommtg://join?action=join&confno=${meetingId}${
      pwd?.length ? '&pwd=' + pwd : ''
    }`;

    if (frameLoader instanceof HTMLIFrameElement) {
      frameLoader.src = zoomLink;
    }
  }

  setTimeout(() => {
    // Only fires if the iframe deep link fails
    if (document.hasFocus()) {
      window.open(url, '_blank');
    }
  }, 500);
};

export function getLocationMetadata(location: string): {
  label?: string;
  href?: string;
  isExternal: boolean;
  isVideoLink: boolean | undefined;
} {
  if (!location) {
    return {
      label: '',
      href: undefined,
      isExternal: false,
      isVideoLink: false,
    };
  }

  const isVideoLink = !!getVideoProvider(location);

  // check if it's in the format https://www.*.* or http://www.*.*,
  // all other strings that are not amongst the video urls we support are treated as a map

  /* eslint-disable no-useless-escape */
  const isExternal =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
      location
    );
  /* eslint-enable no-useless-escape */

  const href = isVideoLink
    ? location
    : isExternal
    ? location
    : `https://www.google.com/maps/search/?api=1&query=${window.encodeURIComponent(
        location
      )}`;

  const label = isVideoLink
    ? 'Join call'
    : isExternal
    ? 'Open URL'
    : 'Open in maps';

  return { label, href, isVideoLink, isExternal };
}
