import { datadogRum } from '@datadog/browser-rum';
import { useLogout } from 'hooks/useLogout';
import { userAtom } from 'hooks/user/userAtoms';
import {
  selectAtom,
  useAtomCallback,
  useAtomValue,
  useUpdateAtom,
} from 'jotai/utils';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { parseCookies, setCookie } from 'nookies';
import { useEffect, useState } from 'react';
import { getIsSecureCookieURL } from 'utils/urls';
import {
  COOKIE_NAME_FIREBASE_ID_TOKEN,
  COOKIE_NAME_OAUTH_TOKEN,
} from '../utils/constants';
import { firebase } from '../utils/services/firebaseShared';
import { DateTime } from 'luxon';
import { perfEnd, perfStart } from './performance';

const auth = firebase.auth();

export const userIdAtom = selectAtom(userAtom, (user) => user?.uid);
const userNameAtom = selectAtom(userAtom, (user) => user?.displayName);
const userFirebaseIdValidAtom = selectAtom(userAtom, (user) =>
  isTokenValid(user?.firebaseIdToken)
);
const userAvatarAtom = selectAtom(userAtom, (user) => user?.photo || undefined);

export function useUserId() {
  return useAtomValue(userIdAtom);
}

export function useGetUserId() {
  return useAtomCallback((get) => get(userIdAtom));
}

export function useUserName() {
  return useAtomValue(userNameAtom);
}
export function useIsFirebaseIdValid() {
  return useAtomValue(userFirebaseIdValidAtom);
}

export function useUserAvatar() {
  return useAtomValue(userAvatarAtom);
}

export function useUser() {
  return useAtomValue(userAtom);
}

export const userEmailAtom = selectAtom(userAtom, (user) => user?.email);

export function useUserEmail() {
  return useAtomValue(userEmailAtom);
}

export function UseHandleAuth() {
  useHandleAuth();
  return null;
}

export function useHandleAuth() {
  const setUser = useUpdateAtom(userAtom);
  const { logout } = useLogout();
  useEffect(() => {
    perfStart('loading-user');
  }, []);
  /**
   * Two different tokens exist due to how Firebase sign-in works.
   *
   * In a nutshell:
   * 1. id_token from provider (called a credential in firebase docs). Our auth provider is Google.
   * 2. id_token from Firebase (obtained after Firebase sign-in with provider credential).
   *
   * https://firebase.google.com/docs/auth/web/google-signin
   *
   */
  const [initialOa2Token, setInitialOa2Token] = useState<string | undefined>();
  const [firebaseToken, setFirebaseToken] = useState<string | undefined>();

  useEffect(() => {
    const getUpdatedToken = async () => {
      try {
        const response = await fetch('/api/auth/check', {
          method: 'POST',
          headers: {
            // Cookies are needed along with this request.
            credentials: 'include',
          },
        }).then((res) => res.json());

        if (!response.oa2Token) {
          throw new Error('No token returned');
        }

        setInitialOa2Token(response.oa2Token);
      } catch (error) {
        console.warn('Unable to fetch token... ');
        logout();
      }
    };

    const cookies = parseCookies();
    const existingOauth2Token = cookies[COOKIE_NAME_OAUTH_TOKEN];
    const existingAccessToken = cookies[COOKIE_NAME_FIREBASE_ID_TOKEN];

    const isTimestampFromCookieValid =
      isTokenValid(existingAccessToken) && isTokenValid(existingOauth2Token);

    // Dirty check... is existing token from cookie still valid by timestamp?
    if (isTimestampFromCookieValid && existingOauth2Token) {
      setInitialOa2Token(existingOauth2Token);
      setFirebaseToken(existingAccessToken);
    } else {
      getUpdatedToken();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (process.env.STORYBOOK_MODE || !initialOa2Token) return;

    const googleCredential =
      firebase.auth.GoogleAuthProvider.credential(initialOa2Token);
    auth
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => auth.signInWithCredential(googleCredential))
      .then(async ({ user }) => {
        if (!user) throw new Error('No user...');

        const newToken = await user.getIdToken();
        setFirebaseToken(newToken);
      })
      .catch((err) => {
        console.error('Cannnot sign in with cred, ', err);
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // The email of the user's account used.
        // const email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        // const credential = error.credential;
      });
  }, [initialOa2Token]);

  useEffect(() => {
    if (process.env.STORYBOOK_MODE || !initialOa2Token || !firebaseToken)
      return;

    const unsubscribe = auth.onIdTokenChanged(async (resolvedUser) => {
      const firebaseIdToken = await resolvedUser?.getIdToken();

      if (!firebaseIdToken || !resolvedUser) {
        setUser(null);
        return logout();
      }

      const commonCookieOpts = {
        expires: DateTime.now().plus({ days: 14 }).toJSDate(),
        sameSite: 'lax' as const,
        path: '/',
        secure: getIsSecureCookieURL(),
      };

      setCookie(
        null,
        COOKIE_NAME_FIREBASE_ID_TOKEN,
        firebaseIdToken,
        commonCookieOpts
      );
      setCookie(
        null,
        COOKIE_NAME_OAUTH_TOKEN,
        initialOa2Token,
        commonCookieOpts
      );

      setUser({
        email: resolvedUser.email || '',
        uid: resolvedUser.uid,
        photo: resolvedUser.photoURL,
        displayName: resolvedUser.displayName || '',
        language: window.navigator.language,
        firebaseIdToken,
        didAuth: true,
      });

      perfEnd('loading-user');
      persistBasicUserInfo({
        id: resolvedUser.uid,
        email: resolvedUser.email || '',
        name: resolvedUser.displayName || '',
      });

      if (process.env.NODE_ENV === 'production') {
        datadogRum.setUser({
          id: resolvedUser.uid,
          email: resolvedUser.email || undefined,
          name: resolvedUser.displayName || undefined,
        });
      }
    });

    return () => unsubscribe();
  }, [firebaseToken, setUser, initialOa2Token, logout]);

  useEffect(() => {
    /**
     * On interactions, make sure the most recent ID token is
     * available for network requests...
     */

    async function handleWindowFocus() {
      try {
        await auth.currentUser?.getIdToken();
      } catch (error) {
        logout();
      }
    }

    window.addEventListener('focus', handleWindowFocus);

    return () => {
      window.removeEventListener('focus', handleWindowFocus);
    };
  }, [setUser, logout]);
}

export function persistBasicUserInfo(user: {
  email: string;
  name: string;
  id: string;
}) {
  localStorage.setItem(
    'amie:currentUser',
    JSON.stringify({
      id: user.id,
      email: user.email,
      name: user.name,
    })
  );
}

export function getPersistedBasicUserInfo() {
  const user = localStorage.getItem('amie:currentUser');
  if (!user) return null;
  return JSON.parse(user);
}

export function isTokenValid(token?: string | null): boolean {
  if (!token) return false;
  try {
    const decodedToken = jwtDecode<JwtPayload>(token);
    const expiresAt = decodedToken.exp;
    if (!expiresAt) return false;
    return expiresAt * 1000 > Date.now();
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function getRefreshedFirebaseIdToken(): Promise<string | null> {
  return new Promise((resolve) => {
    auth.onIdTokenChanged(async (resolvedUser) => {
      if (!resolvedUser) {
        resolve(null);
      }
      const firebaseIdToken = await resolvedUser?.getIdToken();
      resolve(firebaseIdToken || null);
    });
  });
}
