import {
  NewEventVisibilityEnum,
  NewVideoConferenceProvider,
} from '@graphql-types@';
import { getHaveAllOtherGuestsDeclined } from 'components/Grid/utils';
import isEqual from 'fast-deep-equal';
import { urqlClientWithoutSubscriptions } from 'graphql/client';
import { CreateEventMutationVariables } from 'graphql/mutations/CreateEvent.graphql';
import { UpdateEventMutationVariables } from 'graphql/mutations/UpdateEvent.graphql';
import { EventsDocument, EventsQuery } from 'graphql/queries/events.graphql';
import { Attendee, EventDiff, IGridEvent, ServerEvent } from 'types/events';
import { RequestPolicy } from 'urql';
import { PRIVATE_EVENT_PLACEHOLDER_TITLE } from 'utils/events';
import { ObjectKeys } from 'utils/toolbox';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';
import {
  MEET_LINK_PLACEHOLDER,
  MEET_LINK_PLACEHOLDER_DRAFT,
  ZOOM_LINK_PLACEHOLDER,
} from 'utils/video';
import { isEmpty } from 'lodash';

function handleMeetOrZoomOverride(payload: IGridEvent) {
  if (!payload.videoConferences[0]) {
    // Remove previously set video conference links
    return NewVideoConferenceProvider.None;
  }

  if (
    payload.location === MEET_LINK_PLACEHOLDER ||
    payload.location === MEET_LINK_PLACEHOLDER_DRAFT
  ) {
    return NewVideoConferenceProvider.GoogleMeet;
  }
  if (payload.location === ZOOM_LINK_PLACEHOLDER) {
    return NewVideoConferenceProvider.Zoom;
  }

  return undefined;
}

export function formatServerEvent(
  event: ServerEvent,
  userEmail: string,
  timezone: string | undefined
): IGridEvent {
  const startAt = DateTime.fromISO(event.startAt).setZone(timezone);
  const endAt = DateTime.fromISO(event.endAt).setZone(timezone);
  const prevStartAt = DateTime.fromISO(
    event.prevStartAt || event.startAt
  ).setZone(timezone);
  const prevEndAt = DateTime.fromISO(event.prevEndAt || event.endAt).setZone(
    timezone
  );

  const defaultTitle = event.canEdit ? '' : PRIVATE_EVENT_PLACEHOLDER_TITLE;
  return {
    dayIndex: 1,
    ...event,
    title: event.title || defaultTitle,
    recurrenceRules: event.recurrenceRules || [],
    startAt,
    endAt: event.isAllDay
      ? DateTime.max(startAt, endAt.minus({ days: 1 }))
      : DateTime.max(startAt, endAt),
    prevStartAt,
    prevEndAt,
    createdAt: new Date(event.createdAt),
    allOtherGuestsDeclined: getHaveAllOtherGuestsDeclined(
      event.attendees,
      userEmail
    ),
    isDraft: false,
  };
}

export const formatUpdateEventPayload = (
  payload: IGridEvent
): UpdateEventMutationVariables => {
  return {
    ...payload,
    eventId: payload.id,
    startAt: payload.startAt ? payload.startAt.toISO() : null,
    endAt: payload.endAt
      ? payload.isAllDay
        ? payload.endAt.plus({ days: 1 }).toISO()
        : payload.endAt.toISO()
      : null,
    // endAtTimezone: timezone,
    addVideoConference: handleMeetOrZoomOverride(payload),
    attendeesEmailAddresses: payload.attendees.map(
      (attendee) => attendee.email
    ),
  };
};
export const formatCreateEventPayload = (
  payload: IGridEvent
): CreateEventMutationVariables => {
  return {
    ...payload,
    startAt: payload.startAt ? payload.startAt.toISO() : null,
    endAt: payload.endAt
      ? payload.isAllDay
        ? payload.endAt.plus({ days: 1 }).toISO()
        : payload.endAt.toISO()
      : null,
    // endAtTimezone: timezone,
    addVideoConference: handleMeetOrZoomOverride(payload),
    attendeesEmailAddresses: payload.attendees.map(
      (attendee) => attendee.email
    ),
    visibility: payload.visibility || NewEventVisibilityEnum.Default,
  };
};

function isAttendeesKey(
  key: keyof IGridEvent,
  value: IGridEvent[keyof IGridEvent]
): value is Attendee[] {
  return key === 'attendees' && Array.isArray(value);
}

export function getEventDiffProps(
  keysChanged: (keyof IGridEvent)[],
  oldEvent: Partial<IGridEvent>,
  newEvent: Partial<IGridEvent>,
  filterEmail?: string
): EventDiff {
  const result: ReturnType<typeof getEventDiffProps> = {};

  keysChanged.forEach((key) => {
    let oldValue = oldEvent[key];
    let newValue = newEvent[key];

    // Filter out an email from the attendees, and re-set the value
    if (filterEmail && oldValue && isAttendeesKey(key, oldValue)) {
      oldValue = oldValue.filter((attendee) => attendee.email !== filterEmail);
    }
    if (filterEmail && newValue && isAttendeesKey(key, newValue)) {
      newValue = newValue.filter((attendee) => attendee.email !== filterEmail);
    }

    // If both are empty, dont set as a key on the diff
    if (isEmpty(oldValue) && isEmpty(newValue)) return;

    result[key] = {
      old: oldValue as string | number | DateTime | Attendee[],
      new: newValue as string | number | DateTime | Attendee[],
    };
  });

  return result;
}

export function getEventDiff({
  originalEvent,
  updatedEvent,
  whitelistedKeys = [],
}: {
  originalEvent: Partial<IGridEvent> | null;
  updatedEvent: Partial<IGridEvent> | null;
  whitelistedKeys?: Array<keyof IGridEvent>;
}): Array<keyof IGridEvent> {
  if (!updatedEvent) return [];

  const keys = ObjectKeys(updatedEvent);
  if (!originalEvent) return keys;

  const whitelist = whitelistedKeys.length > 0 ? whitelistedKeys : keys;

  return ObjectKeys(updatedEvent).filter((key) => {
    if (!whitelist.includes(key)) return false;
    if (
      (key === 'startAt' || key === 'endAt') &&
      updatedEvent[key] !== undefined
    ) {
      const prevTime = originalEvent[key];
      const currentTime = updatedEvent[key];

      if (currentTime) return prevTime !== currentTime;
    }

    return !isEqual(originalEvent[key], updatedEvent[key]);
  });
}

export function generateEventUUID(): string {
  /**
   * We strip out hyphens from UUID because of Google requirement:
   * https://developers.google.com/calendar/api/v3/reference/events/insert
   *
   * "characters allowed in the ID are those used in base32hex encoding,
   *  i.e. lowercase letters a-v and digits 0-9, see section 3.1.2 in RFC2938"
   */
  return uuidv4().replace(/-/gi, '');
}

export function isGridEvent(event?: IGridEvent | any): event is IGridEvent {
  return !!event && !!event.id;
}

interface ServerEventsQueryParams {
  startAt: DateTime;
  endAt: DateTime;
  calendarIds: string[];
  requestPolicy: RequestPolicy;
  isUserCalendar: boolean;
}
const CURRENT_WEEK_CACHED_EVENTS_KEY = 'currentWeekEvents:v4';

export function cachedEvents({
  startAt,
  endAt,
  requestPolicy,
  isUserCalendar,
}: Omit<ServerEventsQueryParams, 'calendarIds'>) {
  if (
    requestPolicy === 'cache-first' &&
    hasCurrentWeek({ startAt, endAt }) &&
    isUserCalendar
  ) {
    const cachedEvents = getCurrentWeekCachedEvents();
    if (cachedEvents) {
      return cachedEvents;
    }

    return undefined;
  }

  return undefined;
}

export async function serverEventsQuery({
  startAt,
  endAt,
  calendarIds,
  requestPolicy,
  isUserCalendar,
}: ServerEventsQueryParams) {
  return urqlClientWithoutSubscriptions
    .query<EventsQuery>(
      EventsDocument,
      {
        calendarIds,
        startAt: startAt?.toISO(),
        endAt: endAt?.toISO(),
        includeCancelledEvents: true,
      },
      { requestPolicy }
    )
    .toPromise()
    .then((response) => {
      if (
        response.data &&
        response.data.event &&
        hasCurrentWeek({ startAt, endAt }) &&
        isUserCalendar
      ) {
        setCurrentWeekCacheEvents({
          events: response.data?.event.events.filter(
            (event) => event.status !== 'cancelled'
          ),
          startAt,
          endAt,
        });
      }
      return response.data?.event;
    })
    .catch((error) => {
      console.error(error);
      return undefined;
    });
}

export function getVisibilityAsEnum(isPrivate?: boolean) {
  return isPrivate
    ? NewEventVisibilityEnum.Private
    : NewEventVisibilityEnum.Default;
}

function getCurrentWeekCachedEvents(): EventsQuery['event']['events'] | null {
  const cachedEvents = localStorage.getItem(CURRENT_WEEK_CACHED_EVENTS_KEY);
  if (!cachedEvents) return null;
  const todayIsoDate = DateTime.local().toISO();
  const cachedEventsObj = JSON.parse(cachedEvents);
  if (
    cachedEventsObj.startAtIsoDate > todayIsoDate ||
    cachedEventsObj.endAtIsoDate < todayIsoDate
  )
    return null;
  return cachedEventsObj.events || null;
}

function setCurrentWeekCacheEvents({
  events,
  startAt,
  endAt,
}: {
  events: EventsQuery['event']['events'];
  startAt: DateTime;
  endAt: DateTime;
}) {
  return localStorage.setItem(
    CURRENT_WEEK_CACHED_EVENTS_KEY,
    JSON.stringify({
      startAtIsoDate: startAt.toISO(),
      endAtIsoDate: endAt.toISO(),
      events,
    })
  );
}

function hasCurrentWeek(props: {
  startAt: DateTime;
  endAt: DateTime;
}): boolean {
  const today = DateTime.local().toISO();
  return props.startAt.toISO() <= today && today <= props.endAt.toISO();
}
