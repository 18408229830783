import { getRefreshedFirebaseIdToken, isTokenValid } from 'contexts/auth';
import { parseCookies } from 'nookies';
import { COOKIE_NAME_FIREBASE_ID_TOKEN } from '../../utils/constants';
import { firebase } from '../../utils/services/firebaseShared';

import { makeOperation } from '@urql/core';
import { authExchange } from '@urql/exchange-auth';

const fetchAuthExchange = authExchange<{
  firebaseIdToken: string | null;
} | null>({
  addAuthToOperation: ({ authState, operation }) => {
    // the token isn't in the auth state, return the operation without changes
    if (!authState || !authState.firebaseIdToken) {
      return operation;
    }

    // fetchOptions can be a function (See Client API) but you can simplify this based on usage
    const fetchOptions =
      typeof operation.context.fetchOptions === 'function'
        ? operation.context.fetchOptions()
        : operation.context.fetchOptions || {};

    return makeOperation(operation.kind, operation, {
      ...operation.context,
      fetchOptions: {
        ...fetchOptions,
        headers: {
          ...fetchOptions.headers,
          Authorization: `Bearer ${authState.firebaseIdToken}`,
          'x-amie-client': 'web',
        },
      },
    });
  },
  willAuthError: ({ authState }) => {
    if (!authState) return true;
    if (!isTokenValid(authState.firebaseIdToken)) return true;
    // e.g. check for expiration, existence of auth etc
    return false;
  },
  didAuthError: ({ error }) => {
    return error.message.includes('Authorization failed');
  },
  getAuth: async ({ authState }) => {
    // for initial launch, fetch the auth state from storage (local storage, async storage etc)
    if (!authState) {
      const cookies = parseCookies();
      const firebaseIdToken =
        (await firebase.auth().currentUser?.getIdToken()) ||
        cookies[COOKIE_NAME_FIREBASE_ID_TOKEN];
      if (!isTokenValid(firebaseIdToken)) {
        return { firebaseIdToken: await getRefreshedFirebaseIdToken() };
      }

      return { firebaseIdToken };
    }

    /**
     * the following code gets executed when an auth error has occurred
     * we should refresh the token if possible and return a new auth state
     * If refresh fails, we should log out
     **/
    const firebaseIdToken = await getRefreshedFirebaseIdToken();
    return { firebaseIdToken };
  },
});

export default fetchAuthExchange;
