import { arrayMove } from '@dnd-kit/sortable';
import { ColorFamilyEnum_Enum, UpdateTodosInput } from '@graphql-types@';
import { TodosQuery } from 'graphql/queries/todos.graphql';
import { atom } from 'jotai';
import { atomWithDefault, atomWithStorage } from 'jotai/utils';
import { DraggableType } from 'types/drag-and-drop';
import { IGridEvent } from 'types/events';
import { BENEATH, BOTTOM } from './constants';
import { TodoCategoryT, TodoItemT } from './types';

type DragItem = {
  id: string;
  type?: DraggableType | null;
};

/* draftCategoryIdAtom, activeCategoryIdAtom, activeTodoIdAtom, todoDraftPositionAtom:
 * atoms that store ids used in category and todo interactions
 * on Enter, Backspace, Delete and Blur
 * they help determine whether category and todo drafts
 * should be displayed and active as well as
 * the position of active todo draft placeholders
 * and after position and id of created, updated and deleted items
 */

export const draftCategoryIdAtom = atom<string | null>(null);

export const activeCategoryIdAtom = atom<string | null>(null);

export const activeTodoIdAtom = atom<string | null>(null);

export const showArchivedListsAtom = atom<boolean | null>(false);

export const catIdMenuOpenAtom = atom<string | undefined>(undefined);

export const todoDraftPositionAtom = atom<
  'top' | 'bottom' | 'beneath' | 'above' | null
>(null);

/** Atom that stores type and id of the item you're dragging right now */
export const dragAtom = atom<DragItem | null>(null);

/**
 * Atom that stores type and id of the item you're dragging over right now
 */
export const dragOverAtom = atom<DragItem | null>(null);

/** Atom that stores full Category object you're dragging right now */
export const dragCategoryAtom = atom<TodoCategoryT | null>(null);

/** Atom that stores full Todo object you're dragging right now */
export const dragTodoAtom = atom<TodoItemT | null>(null);

export const todoDraftEventIdAtom = atom<string | null>(null);

/**
 * Atom that stores full category object you're dragging over right now
 * Value is set when you're dragging another category or any todo item over it
 */
export const dragOverCategoryAtom = atom<TodoCategoryT | null>(null);

/**
 * Atom that stores full Todo object of item you're dragging over right now
 * Value is only set when you're dragging some other todo item
 */
export const dragOverTodoAtom = atom<TodoItemT | null>(null);

/** Atom that stores event object that was dragged into schedule */
export const dragEventAtom = atom<IGridEvent | null>(null);

const EMPTY = { todos: [], categories: [] };

/** Todos info with local storage cache */
export const cacheTodosAtom = atomWithStorage<TodosQuery>('todosAtom', EMPTY);

/** Main, in memory storage atom for optimistic updates
 *  Stays equal with `cacheTodosAtom` until any mutations
 *  Could be reset to default and after reset it would stay in sync again
 */
export const todosAtom = atomWithDefault<TodosQuery>((get) => {
  return get(cacheTodosAtom) || EMPTY;
});

/** Todos atom for client side predictions & optimistic mutations */
export const optimisticTodosAtom = atom<TodosQuery, UpdateTodosInput>(
  (get) => get(todosAtom) || EMPTY,
  (get, set, upsert) => {
    if (process.env.NODE_ENV === 'development') {
      console.debug(
        '[Todos] Optimistic update\n' + JSON.stringify(upsert, null, 4)
      );
    }
    set(todosAtom, (data) => {
      const { todos } = data || EMPTY;
      let { categories } = data || EMPTY;
      upsert.categories?.forEach((update) => {
        const currentIndex = categories.findIndex(
          (item) => item.id === update.id
        );

        if (currentIndex !== -1) {
          const current = categories[currentIndex];

          if (update.name !== undefined && update.name !== current.name) {
            current.name = update.name;
          }

          if (update.expanded !== undefined && update.expanded !== null) {
            current.expanded = update.expanded;
          }

          if (update.colorFamily !== undefined) {
            const colorFamily = update.colorFamily as unknown as
              | ColorFamilyEnum_Enum
              | undefined;
            current.colorFamily = colorFamily;
          }

          // Because null is first
          if (update.after !== undefined) {
            const index = currentIndex;

            if (
              update.after === null ||
              update.after === get(draftCategoryIdAtom)
            ) {
              categories = arrayMove(categories, index, 0);
            } else {
              const afterIndex = categories.findIndex(
                (entry) => entry.id === update.after
              );

              const isMovingUp = afterIndex < index;
              const moveTo = isMovingUp ? afterIndex + 1 : afterIndex;
              categories = arrayMove(categories, index, moveTo);
            }
          }

          // must run after checks for `update.after !== undefined`
          // where we reorder the categories we will display accordingly before removing
          // archived and deleted ones
          if (update.archivedAt !== undefined || update.deletedAt) {
            // if we have archived or deleted a category
            // we also move it to the top of the archived list
            // thus put it at index 0 first and only then set the archivedAt value
            // otherwise we are trying to update the after value of undefined
            categories.splice(update.after === null ? 0 : currentIndex, 1);
          }
        } else {
          categories.unshift({
            createdAt: new Date().toISOString(),
            id: update.id,
            todos: [],
            archivedAt: null,
            deletedAt: null,
            name: update.name || '',
            expanded: true,
          });
        }
      });

      upsert.todos?.forEach((update) => {
        const currentIndex = todos.findIndex((item) => item.id === update.id);
        const current = todos[currentIndex];
        const updateCategoryId = update.categoryId;

        if (current != null) {
          if (update.name !== undefined) current.name = update.name;
          if (update.doneAt !== undefined) current.doneAt = update.doneAt;

          if (update.deletedAt) {
            todos.splice(currentIndex, 1);
            const deletedTodoCategory = categories.find(
              (category) => category.id === current.categoryId
            );
            if (deletedTodoCategory) {
              const deletedTodoIndex = deletedTodoCategory.todos.findIndex(
                (item) => item.id === update.id
              );
              if (deletedTodoIndex >= 0) {
                deletedTodoCategory?.todos.splice(deletedTodoIndex, 1);
              }
            } else {
              throw new Error(
                'Unable to find deleted todo category ' + update.categoryId
              );
            }
          }

          if (
            updateCategoryId != null &&
            updateCategoryId !== current.categoryId
          ) {
            const currentCatId = current.categoryId;

            const currentCat = categories.find(
              (cat) => cat.id === currentCatId
            );

            const updateCat = categories.find(
              (cat) => cat.id === updateCategoryId
            );

            if (currentCat == null || updateCat == null) {
              throw new Error(
                'Unable to find updated todo category ' + currentCatId
              );
            }

            const currentTodoIndex = currentCat.todos.findIndex(
              (todo) => todo.id === update.id
            );

            if (update.after === null) {
              // add todo item to the dragged over category
              updateCat.todos.unshift(current);
            } else {
              const updateCatTodoIndex = updateCat.todos.findIndex(
                (todo) => todo.id === update.after
              );

              updateCat.todos.splice(updateCatTodoIndex, 0, current);
            }

            // remove todo item from category it got dragged from
            currentCat.todos.splice(currentTodoIndex, 1);
            current.categoryId = update.categoryId;
          }

          // Because null is first
          if (update.after !== undefined) {
            const category = categories.find(
              (category) => category.id === current.categoryId
            );

            if (!category)
              throw new Error('Unable to find category ' + update.categoryId);

            // we could end up with a todo list todos[undefined]
            const index = category.todos.findIndex(
              (entry) => entry.id === current.id
            );
            const afterIndex = category.todos.findIndex(
              (entry) => entry.id === update.after
            );

            if (update.after === null) {
              category.todos = arrayMove(category.todos, index, 0);
            } else {
              const isMovingUp = afterIndex < index;

              const moveTo = isMovingUp ? afterIndex + 1 : afterIndex;
              category.todos = arrayMove(category.todos, index, moveTo);
            }
          }
        } else {
          // Insert todo item data
          todos.push({
            id: update.id,
            name: update.name,
            doneAt: update.doneAt,
            categoryId: update.categoryId,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            size: 0,
            __typename: 'todo',
            type: null,
          });

          // Insert todo into category
          const category = categories.find(
            (category) => category.id === update.categoryId
          );
          if (!category)
            throw new Error('Unable to find category ' + update.categoryId);

          const arr = category.todos.slice();

          const foundItemIndex = arr.findIndex(
            (entry) => entry.id === update.after
          );
          const todoDraftPosition = get(todoDraftPositionAtom);

          if (foundItemIndex === -1) {
            if (todoDraftPosition === BOTTOM || todoDraftPosition === BENEATH) {
              // otherwise insert as last
              arr.push({
                __name: update.name,
                id: update.id,
              });
            } else {
              // if it's draft and is at the first position in a category
              // make it first
              arr.unshift({
                __name: update.name,
                id: update.id,
              });
            }
          } else {
            const insertAt = foundItemIndex + 1;

            arr.splice(insertAt, 0, {
              __name: update.name,
              id: update.id,
            });
          }

          category.todos = arr;
        }
      });

      // Update cache
      set(cacheTodosAtom, { todos, categories });
      return { todos, categories };
    });
  }
);

export const normalizedTodosAtom = atom<Record<string, TodoItemT>>((get) => {
  const { todos } = get(optimisticTodosAtom) || EMPTY;

  const result: Record<string, TodoItemT> = {};
  return todos.reduce((acc, todo) => {
    acc[todo.id] = todo;
    return acc;
  }, result);
});

export const normalizedCategoriesAtom = atom<Record<string, TodoCategoryT>>(
  (get) => {
    const { categories } = get(optimisticTodosAtom) || EMPTY;

    const result: Record<string, TodoCategoryT> = {};
    return categories.reduce((acc, cat) => {
      acc[cat.id] = cat;
      return acc;
    }, result);
  }
);

export const clearDragAtom = atom(null, (_, set) => {
  console.debug('[Todos] Clearing all drag atoms');
  set(dragTodoAtom, null);
  set(dragCategoryAtom, null);
  set(dragOverCategoryAtom, null);
  set(dragOverTodoAtom, null);
  set(dragEventAtom, null);
  set(dragAtom, null);
  set(dragOverAtom, null);
});
