import { destroyCookie } from 'nookies';
import { useCallback } from 'react';
import { firebase } from 'utils/services/firebaseShared';
import {
  COOKIE_NAME_FIREBASE_ID_TOKEN,
  COOKIE_NAME_OAUTH_TOKEN,
} from 'utils/constants';
import { useUpdateAtom } from 'jotai/utils';
import { userAtom } from './user/userAtoms';

const cleanupGraphCache = () =>
  window.indexedDB.deleteDatabase('graphcache-v3');

export function useLogout() {
  const setUser = useUpdateAtom(userAtom);
  const logout = useCallback(async () => {
    destroyCookie(null, COOKIE_NAME_FIREBASE_ID_TOKEN);
    destroyCookie(null, COOKIE_NAME_OAUTH_TOKEN);
    cleanupGraphCache();
    await firebase.auth().signOut();
    setUser(null);
    localStorage.clear();

    window.location.pathname = '/logout';
  }, []); // eslint-disable-line

  return {
    logout,
  };
}
