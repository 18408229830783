import { CalendarStartsOn_Enum, Maybe } from '@graphql-types@';
import { DateTime } from 'luxon';
import { findTimeZone, getZonedTime } from 'timezone-support';
import { EventData } from '../hooks/events/overlap/overlap';

export const MINUTES_IN_A_DAY = 1440;
const TIME_SECOND = 1000;
const TIME_MINUTE = 60 * 1000;

/*
  Allowed inputs (case insensitive):
  1600
  0800
  830
  1530
  16:00
  4:00 pm
  4:00pm
  04:00 pm
  04:00pm
  4
  4 pm
  4pm
*/
export const parseTimeInput = (input: string, timezone = 'UTC'): DateTime => {
  input = input.toLowerCase().trim();

  if (input.length >= 9) return DateTime.invalid('Too long');
  else if (input.length === 0) return DateTime.invalid('Input required');

  let parsedTime = DateTime.now().setZone(timezone).startOf('day');
  let meridiem: string | null = input.slice(-2);

  // Attempt to parse am/pm.
  if (input.length >= 3) {
    if (meridiem === 'am') {
      input = input.substring(0, input.length - 2).trim();
    } else if (meridiem === 'pm') {
      input = input.substring(0, input.length - 2).trim();
    } else if (meridiem.match(/\d/g)?.length !== meridiem.length) {
      // If the last two characters aren't am or pm, and also aren't all numbers, then
      // we can consider this input to be invalid.
      return DateTime.invalid('Invalid AM/PM');
    }
  } else {
    meridiem = null;
  }

  const timeParts = input.split(':');

  try {
    if (timeParts.length === 1) {
      if (timeParts[0].length <= 2) {
        parsedTime = parsedTime.set({
          hour: parseInt(timeParts[0], 10),
        });
      } else if (timeParts[0].length <= 4) {
        // The minute part must be 2 characters long.
        const minutePart = timeParts[0].slice(-2);

        // The hour part can be 1 or 2 characters long.
        const hourPart = timeParts[0].slice(0, timeParts[0].length - 2);

        parsedTime = parsedTime.set({
          hour: parseInt(hourPart, 10),
          minute: parseInt(minutePart, 10),
        });
      } else {
        return DateTime.invalid(
          'Date in 24 hour format cannot be more than 4 characters long.'
        );
      }
    } else {
      parsedTime = parsedTime.set({
        hour: parseInt(timeParts[0], 10),
        minute: timeParts.length === 2 ? parseInt(timeParts[1], 10) : 0,
      });
    }
  } catch (e) {
    return DateTime.invalid('Unable to parse time part.');
  }

  // Ignore AM/PM if they wrote the time in 24 hour format.
  if (meridiem === 'pm' && parsedTime.hour < 12) {
    parsedTime = parsedTime.plus({ hours: 12 });
  } else if (meridiem === 'am' && parsedTime.hour === 12) {
    parsedTime = parsedTime.set({ hour: 0 });
  }

  return parsedTime;
};

export const calculateCalendarTargetDate = (
  currentTime: DateTime,
  weekStartsOn?: Maybe<CalendarStartsOn_Enum>
): DateTime => {
  switch (weekStartsOn) {
    case CalendarStartsOn_Enum.Sunday:
      // NOTE: https://github.com/moment/luxon/issues/373#issuecomment-987030525
      return currentTime
        .startOf('week')
        .plus({ week: currentTime.weekdayShort === 'Sun' ? 1 : 0 })
        .minus({ day: 1 });
    case CalendarStartsOn_Enum.Rolling:
      return currentTime.startOf('day');

    case CalendarStartsOn_Enum.Monday:
    default:
      return currentTime.startOf('week');
  }
};

export const startOfWeek = (
  currentTime: DateTime,
  weekStartsOn?: Maybe<CalendarStartsOn_Enum>
): DateTime => {
  const weekStart = weekStartEnumToNumber(
    weekStartsOn || CalendarStartsOn_Enum.Monday
  );

  const diff =
    (currentTime.weekday < weekStart ? 7 : 0) +
    (currentTime.weekday - weekStart);

  return currentTime.minus({ days: diff });
};

// 6 is Saturday, 7 is Sunday.
export const isWeekend = (time: DateTime): boolean => time.weekday >= 6;

export const isToday = (time: DateTime): boolean =>
  isSameDay(time, DateTime.now().setZone(time.zone));

export const isPast = (time: DateTime): boolean =>
  time < DateTime.now().setZone(time.zone);

export const isFuture = (time: DateTime): boolean =>
  time > DateTime.now().setZone(time.zone);

export const isSameDay = (time: DateTime, time2: DateTime): boolean =>
  time.year === time2.year &&
  time.month === time2.month &&
  time.day === time2.day;

export const roundToNearestQuarterPoint = (value: number): number =>
  Math.round(value * 4) / 4;

export const roundToNearestHalfPoint = (value: number): number =>
  Math.round(value * 2) / 2;

export const roundToNext15Minutes = (time: DateTime): DateTime => {
  return time.set({
    minute: Math.ceil(time.minute / 15) * 15,
  });
};

export const closestIndexTo = (time: DateTime, dates: DateTime[]): number => {
  let closestIndex = 0;

  for (let i = 1; i < dates.length; i++) {
    if (time.diff(dates[i]) < time.diff(dates[closestIndex])) closestIndex = i;
  }

  return closestIndex;
};

// Ported from
// https://github.com/date-fns/date-fns/blob/73f9c6e5ed990986b45ec16cc46ea592cdc150f5/src/differenceInBusinessDays/index.ts
export const differenceInBusinessDays = (
  dateLeft: DateTime,
  dateRight: DateTime
): number => {
  if (!dateLeft.isValid || !dateRight.isValid) return NaN;

  const calendarDifference = dateRight.diff(dateLeft, 'days').days;
  const sign = dateLeft < dateRight ? -1 : 1;

  const weeks = Math.floor(calendarDifference / 7);

  let result = weeks * 5;
  dateRight = dateRight.plus({ days: weeks * 7 });

  // the loop below will run at most 6 times to account for the remaining days that don't makeup a full week
  while (!isSameDay(dateLeft, dateRight)) {
    // sign is used to account for both negative and positive differences
    result += isWeekend(dateRight) ? 0 : sign;
    dateRight = dateRight.plus({ days: sign });
  }

  return result === 0 ? 0 : result;
};

// Formats duration into hour and quarter hour increments like:
// 30m, 45m, 1h, 1h15, 1h30, 1h45, 2h
export function formatDurationMinutes(
  durationMinutes: number,
  {
    long = false,
    withSpace = false,
    trailingMinuteToken = false,
  }: { long?: boolean; withSpace?: boolean; trailingMinuteToken?: boolean } = {}
): string {
  if (durationMinutes === 0) return '';

  if (durationMinutes < 60) {
    return `${Math.round(durationMinutes)}${long ? ' mins' : 'm'}`;
  }

  const hours = Math.floor(durationMinutes / 60);
  const minutes = durationMinutes % 60;

  if (hours === 0) {
    return `${minutes}m`;
  } else if (minutes === 0) {
    return `${hours}h`;
  } else {
    return `${hours}h${withSpace ? ' ' : ''}${minutes}${
      trailingMinuteToken ? 'm' : ''
    }`;
  }
}

export function getCorrespondingWeek({
  date,
  weekStartsOn,
}: {
  date: DateTime;
  weekStartsOn: CalendarStartsOn_Enum;
}): { weekStartsAt: DateTime; weekEndsAt: DateTime } {
  const weekStartsAt = startOfWeek(date, weekStartsOn);
  const weekEndsAt = weekStartsAt.plus({ days: 6 });

  return {
    weekStartsAt,
    weekEndsAt,
  };
}

function weekStartEnumToNumber(weekStartsOn: CalendarStartsOn_Enum): WeekDay {
  if (weekStartsOn === CalendarStartsOn_Enum.Monday) {
    return 1;
  } else if (weekStartsOn === CalendarStartsOn_Enum.Sunday) {
    return 7;
  } else {
    const today = new Intl.DateTimeFormat('en-US', {
      weekday: 'long',
    }).format(new Date());

    switch (today) {
      case 'Sunday':
        return 7;
      case 'Saturday':
        return 6;
      case 'Friday':
        return 5;
      case 'Thursday':
        return 4;
      case 'Wednesday':
        return 3;
      case 'Tuesday':
        return 2;
      case 'Monday':
      default:
        return 1;
    }
  }
}

// Taken from https://v8.dev/features/intl-pluralrules
const pr = new Intl.PluralRules('en-US', {
  type: 'ordinal',
});

const suffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th'],
]);

/**
 * Returns number formatted with plural suffix (2nd, 3rd, 1st, etc)
 */
export const formatOrdinal = (n: number): string => {
  const rule = pr.select(n);
  const suffix = suffixes.get(rule);
  return `${n}${suffix}`;
};

// Luxon weekdays start from 1 (1-based), instead of 0 like date-fns.
// https://moment.github.io/luxon/api-docs/index.html#datetimeweekday
type WeekDay = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export const getBrowserTimezone = (): string => {
  return Intl.DateTimeFormat([]).resolvedOptions().timeZone;
};

/**
 * Converts DateTime to timestamp and strips ms and seconds from it
 * Example: 2022-01-11T05:50:15.911Z -> 1641880200000 (2022-01-11T05:50:00.000Z)
 */
export const toRoundTimestamp = (date: DateTime): number => {
  const timestamp = date.toMillis();
  const roundMs = Math.round(timestamp / TIME_SECOND) * TIME_SECOND;

  return Math.round(roundMs / TIME_MINUTE) * TIME_MINUTE;
};

export const getTimestampInterval = (event: EventData) => {
  return {
    start: toRoundTimestamp(event.startAt),
    end: toRoundTimestamp(event.endAt),
  };
};

export const diffMinutes = (d1: DateTime, d2: DateTime): number => {
  const timestamp1 = toRoundTimestamp(d1);
  const timestamp2 = toRoundTimestamp(d2);

  return (timestamp1 - timestamp2) / TIME_MINUTE;
};

export const getShortTimeZone = (date: DateTime): string => {
  const timezone = findTimeZone(date.zoneName);
  const zonedTime = getZonedTime(date.toJSDate(), timezone);
  return zonedTime.zone?.abbreviation || date.toFormat('ZZZZZ');
};

export const getTimezoneHoursDiff = (
  zone1: string | undefined,
  zone2: string
): number =>
  (DateTime.now().setZone(zone2).offset -
    DateTime.now().setZone(zone1).offset) /
  60;

export function isSameWeek(d1: DateTime, d2: DateTime): boolean {
  return d1.weekYear === d2.weekYear && d1.weekNumber === d2.weekNumber;
}
