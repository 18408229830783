import { useUser } from 'contexts/auth';
import { useIsGridEventsReady } from 'hooks/events/useGridEvents';
import Head from 'next/head';
import React, { useEffect, useState } from 'react';
import { setupAnalytics } from 'utils/analytics';

const segmentAnalytics = `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="potaig3VB1Bw9ahcJqr3xZHd9ijDTaa6";analytics.SNIPPET_VERSION="4.13.2";
  analytics.load("${process.env.NEXT_PUBLIC_SEGMENT_KEY}");
  analytics.page();
  }}();`;

const SegmentAnalytics: React.FC = () => {
  const user = useUser();
  const [isAnalyticsLoaded, setIsAnalyticsLoaded] = useState(false);
  const isGridEventsReady = useIsGridEventsReady();

  useEffect(() => {
    if (!user?.email) return;
    if (isAnalyticsLoaded) {
      // Connect user with analytics events
      setupAnalytics(user);
    }
  }, [user, isAnalyticsLoaded]);

  useEffect(() => {
    let totalChecks = 20;

    const analyticsCheck = setInterval(() => {
      if (totalChecks <= 0) {
        clearInterval(analyticsCheck);
      }

      if (window.analytics) {
        clearInterval(analyticsCheck);
        setIsAnalyticsLoaded(true);
      } else {
        totalChecks--;
      }
    }, 1_000);

    return () => {
      clearInterval(analyticsCheck);
    };
  }, []);

  if (isGridEventsReady === false) return null;

  return (
    <Head>
      <script dangerouslySetInnerHTML={{ __html: segmentAnalytics }} />
    </Head>
  );
};

export default SegmentAnalytics;
