import { getBaseURLForEnvironment } from './urls';

export const HOME_OG_IMAGE_URL = '';
export const ELECTRON_URL = '/electron';

// Next.js API routes
export const API_ROUTE_AUTH_START = '/api/auth/start';
export const API_ROUTE_CODE_GENERATE = '/api/code/generate';
export const API_ROUTE_CODE_VERIFY = '/api/code/verify';

export const COOKIE_NAME_THEME = 'theme';
export const COOKIE_NAME_FIREBASE_ID_TOKEN = 'token';
export const COOKIE_NAME_OAUTH_TOKEN = 'oa2idtoken';
export const COOKIE_NAME_ELECTRON_CODE = 'electron_code';
export const COOKIE_NAME_FEATURE_FLAG = 'feature_flags';
export const COOKIE_NAME_SAFARI_EXCEPTION = 'safari_exception';

export const LOCALSTORAGE_ITEM_IS_CALENDAR_COLLAPSED = 'isCalendarCollapsed';
export const LOCALSTORAGE_ITEM_SAFARI_REDIRECT = 'gotRedirect';

export const LOCALSTORAGE_IS_SIDEPANEL_EXPANDED = 'isSidepanelExpanded';

export const LOCALSTORAGE_LAST_WEEK_PREVIEW_DISMISSED_ON =
  'lastWeekPreviewDismissedOn';

export const LOCALSTORAGE_ITEM_RECENT_TIMEZONES = 'recentTimezones';
export const LOCALSTORAGE_ITEM_TIMEZONE_PREFERENCES = 'timezonePreferences';
export const LOCALSTORAGE_HIDE_DONE = 'todoHideDoneItems';

export const AMIE_PAGE_TITLE = 'Amie';

export const AMIE_PATTERN_BG_HEIGHT = 609;

// Based on Tailwind config
export const VIEWPORT_SIZES = {
  MOBILE: { maxWidth: '639px' },
  SMALL: { maxWidth: '767px' },
  MEDIUM: { maxWidth: '1023px' },
  LARGE: { maxWidth: '1279px' },
  XLARGE: { minWidth: '1280px' },
};

// TEST IDs
export const TEST_ID_LOGIN_BUTTON = 'login';
export const TEST_ID_IS_LOGGED_IN = 'isLoggedIn';
export const TEST_ID_SCHEDULE_DATE = 'scheduleDate';
export const TEST_ID_PREFERENCE_PANEL = 'preferencePanel';
export const TEST_ID_PREFERENCE_PANEL_BUTTON = 'preferencePanelButton';
export const TEST_ID_CLOSE_PREFERENCE_PANEL = 'closePreferencePanel';
export const TEST_ID_INPUT_DATE_FORMAT = 'dateFormatSelect';
export const TEST_ID_SHOWN_CALENDARS = 'shownCalendars';
export const TEST_ID_HIDE_ALL_BUTTON = 'hideAllButton';
export const TEST_ID_EVENT_RESIZE_BOTTOM = 'eventResizeBottom';

export const TEST_ID_POPOVER_EVENT_TITLE = 'popover-event-title';
export const TEST_ID_POPOVER_EVENT_DESCRIPTION = 'popover-event-description';
export const TEST_ID_POPOVER_EVENT = 'popover-event';
export const TEST_ID_ALLDAY_GRID = 'allday-grid';
export const TEST_ID_GRID = 'grid';
export const TEST_ID_GRID_CONTAINER = 'grid-container';
export const TEST_ID_BULK_ACTION_CONTAINER = 'TEST_ID_BULK_ACTION_CONTAINER';

export const ARIA_LABEL_TODO_POPOVER_OPTIONS = 'show todo popover options';
export const ARIA_LABEL_TODO_ADD_CATEGORY = 'add a todo category';
export const ARIA_LABEL_CLOSE_ARCHIVED_LIST = 'close archived lists';

export const ARIA_LABEL_RIGHT_CLICK_MENU_OPTIONS =
  'show right click menu options';

export const ARIA_LABEL_EVENT_POPOVER_OPTIONS = 'event-popover-options';
export const ARIA_LABEL_ICON_LOCK_CLOSED = 'event is private';
export const ARIA_LABEL_ICON_LOCK_OPEN = 'event is public';

export const TEST_ID_GUEST_AVATAR = 'guest-avatar';
export const TEST_ID_ICON_MAP_PIN = 'icon-map-pin';

export const TEST_ID_EVENT_TIME_PICKER_INPUT = 'event-time-picker-input';

// Login button
export enum GOOGLE_LOGIN_BUTTON {
  DISABLED = '/assets/google-login/btn_google_signin_light_disabled_web@2x.png',
  FOCUSED = '/assets/google-login/btn_google_signin_light_pressed_web@2x.png',
  PRESSED = '/assets/google-login/btn_google_signin_light_focus_web@2x.png',
  NORMAL = '/assets/google-login/btn_google_signin_light_normal_web@2x.png',
}

export const DEEP_LINK_IFRAME_ID = 'deepLinkIframe';

export const MINUTES_REGEX =
  /(for\s)?(\d+)\s?(m|mns?|mins?|minutes?)(?:\s|(?!.))/i;
export const HOURS_REGEX =
  /(for\s)?(\d+([\\.|,])?\d?\s?)(h|hrs?|hours?)(?:\s|(?!.))/i;

export const APPLE_REGEX = /iOS|iPhone|iPad|Macintosh/;

// GraphQL Error Codes
export const EVENT_OPERATION_NOT_AUTHORIZED = 'EVENT_OPERATION_NOT_AUTHORIZED';
export const NO_ZOOM_VIDEO_CONFERENCE_LINK_SET =
  'NO_ZOOM_VIDEO_CONFERENCE_LINK_SET';

// Message codes for URLs
export enum MESSAGE_CODES {
  ZoomFail,
  ZoomSuccess,
  SpotifyFail,
  SpotifySuccess,
}

export const MESSAGE_CODE_MESSAGES = {
  [MESSAGE_CODES.ZoomFail]: 'Sorry, could not connect your Zoom account',
  [MESSAGE_CODES.ZoomSuccess]: 'Your Zoom account is now connected',
};

export const ZOOM_REDIRECT_URL = `${getBaseURLForEnvironment()}/zoom-callback`;
