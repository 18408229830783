import React, { useCallback, useEffect, useRef } from 'react';
import createCtx from './createCtx';
import { HotkeyScope } from 'types/hotkeys';
import hotkeys from 'hotkeys-js';

type THotkeyContext = {
  getScope: () => HotkeyScope;
  setScope: (scope: HotkeyScope) => void;
  setPreviousScope: () => void;
};

export const [useHotkeyContext, HotkeyContext] = createCtx<THotkeyContext>();

export const HotkeyProvider: React.FC = ({ children }) => {
  const initialScope = useRef<HotkeyScope>('global');
  const scopeStack = useRef<HotkeyScope[]>([initialScope.current]);

  const getScope = useCallback(() => hotkeys.getScope() as HotkeyScope, []);
  const setScope = useCallback((newScope: HotkeyScope, ignoreStack = false) => {
    if (newScope === undefined) return;
    if (
      !ignoreStack &&
      scopeStack.current[scopeStack.current.length - 1] !== newScope
    ) {
      scopeStack.current.push(newScope);
    }

    hotkeys.setScope(newScope);
  }, []);

  const setPreviousScope = useCallback(() => {
    if (scopeStack.current.length === 1) return;
    scopeStack.current.pop();

    setScope(scopeStack.current[scopeStack.current.length - 1], true);
  }, [setScope]);

  useEffect(() => {
    // Set initial scope on component mount
    hotkeys.setScope(initialScope.current);
  }, []);

  return (
    <HotkeyContext value={{ getScope, setScope, setPreviousScope }}>
      {children}
    </HotkeyContext>
  );
};
