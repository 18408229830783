/* 34a193137d1afb1a5eaf0293979fe707dbbbe856
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EventsQueryVariables = Types.Exact<{
  startAt?: Types.InputMaybe<Types.Scalars['DateTimeRFC3339']>;
  endAt?: Types.InputMaybe<Types.Scalars['DateTimeRFC3339']>;
  calendarIds?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  isUserEventCreator?: Types.InputMaybe<Types.Scalars['Boolean']>;
  includeCancelledEvents?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type EventsQuery = { __typename?: 'query_root', event: { __typename?: 'EventsResponse', calendars: Array<{ __typename?: 'CalendarRole', id: string, accessRole: Types.AccessRole }>, events: Array<{ __typename?: 'NewEvent', calendarId: string, description?: string | null, title: string, id: string, createdAt: string, doneAt?: string | null, doneBy?: string | null, location?: string | null, colorFamily?: Types.ColorFamily | null, rsvp: Types.NewEventRsvpEnum, isOwnEvent: boolean, canEdit: boolean, isSelfAsAttendee: boolean, belongsToUserCalendar: boolean, eventType?: string | null, recurringEventId?: string | null, recurrenceRules?: Array<string> | null, startAt: any, endAt: any, isAllDay: boolean, prevStartAt: any, prevEndAt: any, status: string, visibility: Types.NewEventVisibilityEnum, attendees: Array<{ __typename?: 'NewEventAttendee', avatar?: string | null, displayName?: string | null, firstName?: string | null, id: string, lastName?: string | null, RSVP: Types.NewEventRsvpEnum, email: string, organizer: boolean, responseStatus: Types.NewEventAttendeeResponseStatusEnum }>, creator?: { __typename?: 'EventCreator', id?: string | null, self?: boolean | null } | null, videoConferences: Array<{ __typename?: 'NewEventVideoConference', link: string, provider: Types.NewVideoConferenceProvider }> }> } };

export type EventFragmentFragment = { __typename?: 'NewEvent', calendarId: string, description?: string | null, title: string, id: string, createdAt: string, doneAt?: string | null, doneBy?: string | null, location?: string | null, colorFamily?: Types.ColorFamily | null, rsvp: Types.NewEventRsvpEnum, isOwnEvent: boolean, canEdit: boolean, isSelfAsAttendee: boolean, belongsToUserCalendar: boolean, eventType?: string | null, recurringEventId?: string | null, recurrenceRules?: Array<string> | null, startAt: any, endAt: any, isAllDay: boolean, prevStartAt: any, prevEndAt: any, status: string, visibility: Types.NewEventVisibilityEnum, attendees: Array<{ __typename?: 'NewEventAttendee', avatar?: string | null, displayName?: string | null, firstName?: string | null, id: string, lastName?: string | null, RSVP: Types.NewEventRsvpEnum, email: string, organizer: boolean, responseStatus: Types.NewEventAttendeeResponseStatusEnum }>, creator?: { __typename?: 'EventCreator', id?: string | null, self?: boolean | null } | null, videoConferences: Array<{ __typename?: 'NewEventVideoConference', link: string, provider: Types.NewVideoConferenceProvider }> };

export const EventFragmentFragmentDoc = gql`
    fragment EventFragment on NewEvent {
  attendees {
    avatar
    displayName
    firstName
    id
    lastName
    RSVP
    email
    organizer
    responseStatus
  }
  calendarId
  description
  title
  id
  createdAt
  creator {
    id
    self
  }
  doneAt
  doneBy
  location
  colorFamily
  rsvp
  isOwnEvent
  canEdit
  isSelfAsAttendee
  belongsToUserCalendar
  eventType
  recurringEventId
  recurrenceRules
  startAt
  endAt
  isAllDay
  prevStartAt
  prevEndAt
  status
  videoConferences {
    link
    provider
  }
  visibility
}
    `;
export const EventsDocument = gql`
    query Events($startAt: DateTimeRFC3339, $endAt: DateTimeRFC3339, $calendarIds: [String!], $isUserEventCreator: Boolean, $includeCancelledEvents: Boolean) {
  event(
    where: {startAt: $startAt, endAt: $endAt, inCalendars: {ids: $calendarIds}, isUserEventCreator: $isUserEventCreator, includeCancelledEvents: $includeCancelledEvents}
  ) {
    calendars {
      id
      accessRole
    }
    events {
      ...EventFragment
    }
  }
}
    ${EventFragmentFragmentDoc}`;

export function useEventsQuery(options?: Omit<Urql.UseQueryArgs<EventsQueryVariables>, 'query'>) {
  return Urql.useQuery<EventsQuery>({ query: EventsDocument, ...options });
};