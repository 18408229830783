// All classes used for event card colors in the popover
const generateColorMap = (value) => {
  return {
    colorSwatchBg: `bg-${value}-100 dark:bg-${value}-600`,
    solidBg: `bg-${value}-400`,

    border: `border-${value}-400`,
    ring: `ring-${value}-400`,
    button: `hover:bg-${value}-100 focus:bg-${value}-100 dark:hover:bg-${value}-500/20 dark:focus:bg-${value}-500/20`,
    popoverButton: `hover:scale(110) ${
      value === 'gray'
        ? `bg-${value}-200 dark:bg-${value}-500`
        : `bg-${value}-100 dark:bg-${value}-500/20`
    }`,
    popoverButtonText: `text-${value}-500 dark:text-${value}-300`,
    text: `text-${value}-500 dark:text-${value}-300`,
    todoText: `text-${value}-800 dark:text-${value}-200`,
    todoDragBackground: `bg-${value}-200/20 dark:bg-${value}-400/10`,
    todoButton: `hover:bg-${value}-200/20 dark:hover:bg-${value}-400/10`,
    todoButtonText: `text-${value}-800/70 dark:text-${value}-200/70 hover:text-${value}-800 dark:hover:text-${value}-200`,
    todoDropIndicator: `bg-${value}-800/50 dark:bg-${value}-200/50`,
    todoButtonPlaceholderText: `placeholder-${value}-800/50 dark:placeholder-${value}-200/50`,
    placeholder: `placeholder-${value}-500/60 dark:placeholder-${value}-300/70`,
    focusBackground: `bg-${value}-50 dark:bg-${value}-400/10`,
    selection: `selection:bg-${value}-600/60 dark:selection:bg-${value}-300/40 selection:text-white`,

    // ScheduleEventView classnames
    scheduleEventBase: getBaseEventClasses(value),
    scheduleEventSelected: getSelectedEventClasses(value),

    // event details title circle checkbox
    checkbox: `text-gray-400/70 border-gray-400/70 hover:text-${value}-400/70 hover:border-${value}-400/70 focus:text-${value}-400/70 focus:border-${value}-400/70`,
    checkboxFocused: `text-${value}-400/70 border-${value}-400/70`,
    checkboxChecked: `bg-${value}-400 border-${value}-400 text-white`,

    // schedule event regular checkbox
    scheduleCheckbox: `shadow-checkbox-${value}-800 dark:shadow-checkbox-${value}-200`,
    scheduleCheckboxChecked: `bg-${value}-800 dark:bg-${value}-200`,
    scheduleCheckboxSlash: `relative before:content-[""] before:absolute before:h-2.5 before:w-0.5 before:bg-${value}-800 dark:before:bg-${value}-200 tranform before:rotate-45 before:rounded-sm`,
    scheduleCheckboxSlashSelected: `relative before:content-[""] before:absolute before:h-2.5 before:w-0.5 before:bg-${value}-200 dark:before:bg-${value}-800 tranform before:rotate-45 before:rounded-sm`,
    scheduleCheckboxSelected: `shadow-checkbox-${value}-100 dark:shadow-checkbox-${value}-800`,
    scheduleCheckboxSelectedChecked: `bg-${value}-100 dark:bg-${value}-800`,
    scheduleCheckboxIcon: getCheckboxIconClasses(value),
    scheduleCheckboxSelectedIcon: `text-${value}-600 dark:text-${value}-200`,

    // Event is all-day icon button
    buttonAllDayEnabled: `!bg-${value}-50 focus:!bg-${value}-100 dark:!bg-${value}-500/10 dark:focus:!bg-${value}-500/20`,
    buttonAllDayEnabledHover: `hover:!bg-${value}-100 dark:hover:!bg-${value}-500/20`,

    // Home
    homeEventText: `text-${value}-600 dark:text-${value}-300`,
    homeEventBorder: `bg-${value}-600/30 dark:bg-${value}-300/30`,
    homeEventDateBackground: `bg-${value}-500/[12%]`,
    homeCirclularProgressStroke: `text-${value}-500 dark:text-${value}-400`,
    homeEventGradient: `bg-gradient-to-r from-${value}-500/[15%] dark:from-${value}-500/10 to-gray-100 dark:to-gray-750`,

    // Room
    roomCursor: `dark:text-${value}-500 text-${value}-400`,
    roomCursorTextBackground: `dark:bg-${value}-500 bg-${value}-400`,
  };
};

// Keep it typesafe for when importing in to a TS file
const colorMap = {
  blue: generateColorMap('blue'),
  purple: generateColorMap('purple'),
  yellow: generateColorMap('yellow'),
  red: generateColorMap('red'),
  pink: generateColorMap('pink'),
  green: generateColorMap('green'),
  orange: generateColorMap('orange'),
  gray: generateColorMap('gray'),
};

function getCheckboxIconClasses(color) {
  if (color === 'gray') {
    return `text-${color}-100 dark:text-${color}-500`;
  }

  return `text-${color}-100 dark:text-${color}-800`;
}

function getBaseEventClasses(color) {
  if (color === 'gray') {
    return `text-${color}-800 bg-${color}-200 hover:bg-${color}-300 dark:text-${color}-200 dark:bg-${color}-600 dark:hover:bg-${color}-500`;
  }

  return `text-${color}-800 bg-${color}-100 hover:bg-${color}-200 dark:text-${color}-200 dark:bg-${color}-900 dark:hover:bg-${color}-800`;
}

function getSelectedEventClasses(color) {
  if (color === 'gray') {
    return `bg-${color}-600 text-white dark:bg-${color}-200 dark:text-${color}-900`;
  }

  return `bg-${color}-600 text-white dark:bg-${color}-200 dark:text-${color}-900`;
}

// Generate a safe list for all classes used to let Tailwind build those in the CSS file
const getClassSafeList = () => {
  const safeList = [
    'grid-cols-calendar1Timezones',
    'grid-cols-calendar2Timezones',
    'grid-cols-calendar3Timezones',
    'grid-cols-calendar4Timezones',
    'grid-cols-calendar5Timezones',
  ];

  // TODO: This doesn't seem to be working, but it would be better than manually listing them above.
  // const numTimezonesAllowed = 5;
  // for (let i = 1; i <= numTimezonesAllowed; i++) {
  //   safeList.push(`grid-cols-calendar${i}Timezones`);
  // }

  Object.values(colorMap).forEach((colors) => {
    Object.values(colors).forEach((value) => {
      safeList.push(...value.split(' '));
    });
  });

  return Array.from(new Set(safeList));
};

const classesSafeList = getClassSafeList();

module.exports = { colorMap, classesSafeList };
