import {
  ProfileMeetInfoQueryVariables,
  useProfileMeetInfoQuery,
} from 'graphql/queries/profile.graphql';
import { atom } from 'jotai';
import { atomFamily, useAtomValue, useUpdateAtom } from 'jotai/utils';
import { useEffect, useMemo } from 'react';
import { TProfileMeetInfo } from 'types/profile';
import type { UseQueryArgs } from 'urql';

type QueryType = Omit<UseQueryArgs<ProfileMeetInfoQueryVariables>, 'query'>;

interface Props {
  email: string;
}

export const isProfileSeenReadyAtom = atom(false);

export function useIsProfileSeenReady(): boolean {
  return useAtomValue(isProfileSeenReadyAtom);
}

const profileSeenFamily = atomFamily(() => atom<TProfileMeetInfo | null>(null));

export default function useProfileSeen(email: string): TProfileMeetInfo | null {
  return useAtomValue(profileSeenFamily(email));
}

export function useProfileSeenUpdate(
  email: string
): (profile: TProfileMeetInfo) => void {
  return useUpdateAtom(profileSeenFamily(email));
}

export function SyncProfileSeen({ email }: Props): null {
  const setIsReady = useUpdateAtom(isProfileSeenReadyAtom);
  const updateProfile = useProfileSeenUpdate(email);

  const options = useMemo<QueryType>(
    () =>
      email
        ? {
            variables: { email },
            context: {
              requestPolicy: 'cache-and-network' as const,
              suspense: true,
            },
          }
        : { pause: true },
    [email]
  );
  const [queryResults] = useProfileMeetInfoQuery(options);

  useEffect(() => {
    if (!queryResults?.data?.profileMeetInfo) return;

    setIsReady(true);
    updateProfile(queryResults.data.profileMeetInfo);
  }, [queryResults.data?.profileMeetInfo, setIsReady, updateProfile]);

  return null;
}
