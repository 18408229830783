import { DragStartEvent } from '@dnd-kit/core';
import {
  NewEventAttendee,
  NewEventRsvpEnum as _NewEventRsvpEnum,
} from '@graphql-types@';
import { CalendarSlotDate } from 'hooks/calendarLink/booking/atoms';
import { clamp } from 'lodash';
import { DateTime } from 'luxon';
import { DraggableType } from 'types/drag-and-drop';
import { EventBox, IGridDay, IGridEvent } from 'types/events';
import { NormalizedPreferences, UserTimeFormatPreference } from 'utils/format';
import { isSlotRecurring } from 'utils/share';
import { isSameDay } from 'utils/time';

export const SPOTIFY_BLOCK_WIDTH = 10;
export const SPOTIFY_BLOCK_HEIGHT = 4;
export const Z_INDEX_SPOTIFY_TRACKS = 10;
export const Z_INDEX_SLOTS = 20;

const NewEventRsvpEnum = _NewEventRsvpEnum;

export const days = Array(7)
  .fill(null)
  .map((_, i) => i + 1);

export const times = Array(24)
  .fill(null)
  .map((_, i) => i + 1);

export const gridPosition = {
  labels: 1,
  isAllDay: 0,
  columnStart: 2,
  rowStart: 1,
};

export function isDraftEvent(event?: Partial<IGridEvent> | null): boolean {
  return !!event?.isDraft;
}

export function isEvent(event: IGridEvent): event is IGridEvent {
  return !isDraftEvent(event);
}

export function isEventSelected(
  selection: string[],
  event: { id: string }
): boolean {
  return selection.some((eventId) => eventId === event.id);
}

export function rectIntersects(a: EventBox, b: EventBox): boolean {
  return !(b.x >= a.x2 || b.x2 <= a.x || b.y >= a.y2 || b.y2 <= a.y);
}

export function getTimeLabel(
  hour: number,
  options: { ui24HourClock: NormalizedPreferences['ui24HourClock'] }
): string {
  if (options.ui24HourClock && hour === 24) {
    return `00:00`;
  }
  if (options.ui24HourClock) {
    return `${hour}:00`;
  }

  if (hour < 0) hour += 24;

  if (hour === 0) return '12 am';
  if (hour < 12) return `${hour} am`;
  if (hour > 24) return `${hour - 24} am`;
  if (hour > 12) return `${hour - 12} pm`;
  return 'Noon';
}

export function formatTime(
  timeFormatPreference: UserTimeFormatPreference,
  time: DateTime
): string {
  const startTimeFormat =
    time.minute === 0 ? timeFormatPreference.short : timeFormatPreference.long;

  return time.toFormat(startTimeFormat);
}

export function getDateIndex(date: DateTime, days: IGridDay[]): number {
  const gridDay = days.find((day) => isSameDay(date, day.date));
  // Give it a -1 if the day doesn't exist in the week
  const dayIndex = gridDay?.index ?? -1;

  return dayIndex;
}

export function getHaveAllOtherGuestsDeclined(
  attendees: NewEventAttendee[],
  userEmail: string
): boolean {
  if (attendees.length === 0) {
    return false;
  }

  if (attendees.length === 1 && attendees[0].email === userEmail) {
    return false; // I'm the only guest, no warning needed for me declining
  }

  return attendees.every((attendee) => {
    if (attendee.email === userEmail) {
      return true; // not taking the user's own RSVP status into account
    }

    return attendee.RSVP === NewEventRsvpEnum.No;
  });
}

/**
 * Returns the time to the next meeting or 15min if it's less than 15min.
 */
export function getAvailableTimeToNextEvent({
  date,
  events,
  maxDurationMinutes,
}: GetAvailableTimeToNextEventProps): number {
  const nextEvent = events
    .filter((event) => isSameDay(date, event.startAt) && !event.isAllDay)
    .find((event) => event.startAt > date);

  if (!nextEvent) {
    return maxDurationMinutes;
  }

  const diffMinutes = Math.round(
    nextEvent.startAt.diff(date, 'minutes').minutes
  );

  return clamp(diffMinutes, 15, maxDurationMinutes);
}

export type GetAvailableTimeToNextEventProps = {
  date: DateTime;
  events: Pick<IGridEvent, 'startAt' | 'isAllDay'>[];
  maxDurationMinutes: number;
};

export const MIN_DURATION_HEIGHT = 15; // 15minutes is smallest event height;
export const COLUMN_END_PADDING = 12;
const SLOT_WIDTH = 12;

export function getEventStyle({
  event,
  dayIndex,
  previewOnly,
  rowPosition,
}: {
  event: IGridEvent;
  dayIndex: number;
  previewOnly?: boolean;
  calendarLinkMode?: boolean;
  rowPosition?: number;
}): React.CSSProperties {
  const { isAllDay, startAt, endAt, overlap } = event;
  const isDraft = isDraftEvent(event);
  const isDefaultStyle = isAllDay || previewOnly || isMultiDay(event);
  const hour = startAt.get('hour');
  const minute = startAt.get('minute');

  const durationDays = getDurationDays(event);
  const durationMinutes = endAt.diff(startAt, 'minutes').minutes;
  const pxDiff = 1;

  let width = `100% - ${pxDiff}px - ${COLUMN_END_PADDING}px`;
  let marginLeft = `${pxDiff}px`;
  let zIndex = 0;

  if (overlap) {
    width = `${overlap.styles.width} - ${pxDiff}px`;
    marginLeft = `${overlap.styles.marginLeft} + ${pxDiff}px`;
    zIndex = overlap.styles.zIndex;
  }

  const gridRow =
    isAllDay || isMultiDay(event) ? rowPosition : hour + gridPosition.rowStart;

  const gridColumn = `${dayIndex + gridPosition.columnStart} / ${
    dayIndex + gridPosition.columnStart + durationDays
  }`;

  return {
    gridColumn,
    gridRow,
    minHeight: '25%',
    zIndex: isDraft ? zIndex + 10 : zIndex,
    top: isDefaultStyle ? 0 : `calc(100% * ${minute / 60})`,
    marginLeft: `calc(${marginLeft} + ${SLOT_WIDTH}px)`,
    height: isDefaultStyle
      ? `calc(100% - ${pxDiff}px)`
      : `calc((100% * ${durationMinutes / 60}) - ${pxDiff}px)`,
    width,
  };
}
export function getSlotStyle({
  slot,
  dayIndex,
  timezone,
}: {
  slot: CalendarSlotDate;
  dayIndex: number;
  timezone?: string;
}): React.CSSProperties {
  const startAt = slot.startAt.setZone(timezone);
  const endAt = slot.endAt.setZone(timezone);
  const hour = startAt.hour;
  const minute = startAt.minute;
  const durationMinutes = endAt.diff(startAt, 'minutes').minutes;
  const pxDiff = 1;

  const marginLeft = isSlotRecurring(slot)
    ? `calc(${pxDiff}px + ${SLOT_WIDTH}px)`
    : `${pxDiff}px`;
  const width = isSlotRecurring(slot)
    ? `calc(100% - ${SLOT_WIDTH}px - ${COLUMN_END_PADDING}px - ${pxDiff}px)`
    : '12px';
  const zIndex = 0;

  const gridRow = hour + gridPosition.rowStart;

  const gridColumn = `${dayIndex + gridPosition.columnStart} / ${
    dayIndex + gridPosition.columnStart + 1
  }`; // FIXME: +1 is a hack to make the slot span one day

  return {
    gridColumn,
    gridRow,
    zIndex: zIndex,
    top: `calc(100% * ${minute / 60})`,
    marginLeft,
    height: `calc((100% * ${durationMinutes / 60}) - ${pxDiff}px)`,
    width,
  };
}

export function isString(value?: unknown): value is string {
  return typeof value === 'string';
}

export function getDayIndex(
  days: IGridDay[],
  event: Pick<IGridEvent, 'startAt'> | null
): number {
  return event
    ? Math.max(
        0,
        days.findIndex((day) => isSameDay(day.date, event.startAt)) + 1
      )
    : 0;
}

export function getDurationDays(event: IGridEvent): number {
  return (
    event.endAt.startOf('day').diff(event.startAt.startOf('day'), 'days').days +
    (event.isAllDay || isMultiDay(event) ? 1 : 0)
  );
}

export function isEventGhostEnabled(
  active: DragStartEvent['active'] | null
): boolean {
  return active?.data.current?.type === DraggableType.EVENT;
}

export function isSlotGhostEnabled(
  active: DragStartEvent['active'] | null
): boolean {
  return active?.data.current?.type === DraggableType.SLOT;
}

export function isMultiDay(event?: IGridEvent | null): boolean {
  if (!event) return false;
  return event.startAt.ordinal !== event.endAt.ordinal;
}
