/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { contactCalendarLoadingFamily } from 'hooks/contacts/useIsContactCalendarLoading';
import useCalendar from 'hooks/useCalendar';
import { userAtom } from 'hooks/user/userAtoms';
import { timezoneAtom } from 'hooks/useTimeZone';
import { Getter, Setter } from 'jotai';
import { useAtomCallback, useAtomValue, useUpdateAtom } from 'jotai/utils';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { IGridEvent } from 'types/events';
import { RequestPolicy } from 'urql';
import {
  getPreferredColorFamilyRanking,
  reverseColorFamilyRankingAtom,
} from 'utils/calendarColorFamily';
import { EventsQuery } from '../../graphql/queries/events.graphql';
import {
  gridEventsFamily,
  interactionOnlyEventsFamily,
  isGridEventsReadyAtom,
  nextWeekEventsAtom,
  optimisticEventsFamily,
  eventIdsPoolAtom,
  serverEventsAtomFamily,
  visibleEventsAtom,
  visibleEventsIdsAtom,
} from './eventAtoms';
import {
  cachedEvents,
  formatServerEvent,
  serverEventsQuery,
} from './helpers/eventsHelpers';

/**
 * Returns the currently selected week events
 */
export function useVisibleEvents(): IGridEvent[] {
  return useAtomValue(visibleEventsAtom);
}

export function useVisibleEventsIds(): string[] {
  return useAtomValue(visibleEventsIdsAtom);
}

export function useNextWeekEvents(): IGridEvent[] {
  return useAtomValue(nextWeekEventsAtom);
}

/**
 * Get a single event
 */
export function useGridEvent(id: string): IGridEvent | null {
  return useAtomValue(gridEventsFamily(id));
}

export function useInteractionOnlyEvent(id: string) {
  return useAtomValue(interactionOnlyEventsFamily(id));
}

export function useGetGridEvent() {
  return useAtomCallback((get, _, id: string) => get(gridEventsFamily(id)));
}

export function useIsGridEventsReady() {
  return useAtomValue(isGridEventsReadyAtom);
}

type ServerEvents = EventsQuery['event']['events'];

export function useExtendedFetchEvents({
  requestPolicy,
  plusWeek,
  minusWeek,
}: {
  requestPolicy?: RequestPolicy;
  plusWeek?: number;
  minusWeek?: number;
} = {}) {
  const { startDate, endDate } = useCalendar();
  return useFetchEvents({
    startAt: startDate.minus({ weeks: minusWeek ?? 1 }),
    endAt: endDate.plus({ weeks: plusWeek ?? 3 }),
    requestPolicy: requestPolicy || 'network-only',
  });
}

export function useFetchEvents({
  startAt,
  endAt,
  requestPolicy = 'network-only',
}: {
  startAt: DateTime;
  endAt: DateTime;
  requestPolicy?: RequestPolicy;
}) {
  const fetchEvents = useAtomCallback(_fetchEvents);
  const storeEvents = useAtomCallback(_storeEvents);
  const storeEventIds = useAtomCallback(_storeEventIds);
  const setReady = useUpdateAtom(isGridEventsReadyAtom);
  const updateColorRanking = useAtomCallback(
    (get, set, args: { events: ServerEvents; calendarId: string }) => {
      const user = get(userAtom);
      const isUserCalendar = args.calendarId === user?.email;
      const isEmptyColorRanking =
        get(reverseColorFamilyRankingAtom).length === 0;

      if (isUserCalendar && isEmptyColorRanking) {
        set(
          reverseColorFamilyRankingAtom,
          getPreferredColorFamilyRanking(args.events).reverse()
        );
      }
    }
  );

  return useCallback(
    async (calendarId: string) => {
      const params = {
        calendarId,
        startAt,
        endAt,
        requestPolicy,
      };

      const serverEvents = await fetchEvents(params);
      if (!serverEvents) {
        return;
      }

      storeEvents(serverEvents);
      storeEventIds({
        events: serverEvents,
        params,
      });
      setReady(true);
      updateColorRanking({ calendarId, events: serverEvents });
    },
    [
      startAt,
      endAt,
      requestPolicy,
      fetchEvents,
      storeEvents,
      storeEventIds,
      setReady,
      updateColorRanking,
    ]
  );
}

export function useSyncVisibleCalendarEvents() {
  const { startDate, endDate } = useCalendar();
  return useFetchEvents({
    startAt: startDate,
    endAt: endDate,
  });
}

// ========== UTILS ============ //

interface FetchEventsProps {
  startAt: DateTime;
  endAt: DateTime;
  calendarId: string;
  requestPolicy: RequestPolicy;
}

const _fetchEvents = async (
  get: Getter,
  set: Setter,
  { calendarId, startAt, endAt, requestPolicy }: FetchEventsProps
) => {
  const currentWeekEvents = get(eventIdsPoolAtom);

  const isInitialLoad = currentWeekEvents.size === 0;
  const loadingAtom = contactCalendarLoadingFamily(calendarId);
  set(loadingAtom, isInitialLoad);

  // fetch the events, then normalize them

  const params = {
    startAt: startAt,
    endAt: endAt,
    calendarIds: [calendarId],
    requestPolicy,
    isUserCalendar: calendarId === get(userAtom)?.email,
  };

  const cached = cachedEvents(params);
  if (cached) {
    set(loadingAtom, false);
    return cached;
  }

  const serverEvents = await serverEventsQuery(params);
  set(loadingAtom, false);
  /**
   * TODO: set serverEvents.calendars with accessRoles somewhere
   * when needed.
   */

  return serverEvents?.events || [];
};

const _storeEvents = (get: Getter, set: Setter, events: ServerEvents) => {
  const user = get(userAtom);
  const timezone = get(timezoneAtom);

  if (!user) {
    return;
  }

  events.forEach((event) => {
    const existingEvent = get(serverEventsAtomFamily(event.id));
    if (event.status === 'cancelled' && existingEvent) {
      set(optimisticEventsFamily(event.id), { status: 'cancelled' });
      return;
    }
    if (
      event.status === 'cancelled' ||
      (existingEvent && event.calendarId !== user.email)
    ) {
      return;
    }
    set(
      serverEventsAtomFamily(event.id),
      formatServerEvent(event, user.email, timezone)
    );
  });
};

const _storeEventIds = (
  _get: Getter,
  set: Setter,
  {
    events,
  }: {
    events: ServerEvents;
    params: FetchEventsProps;
  }
) => {
  set(eventIdsPoolAtom, (previousServerEventIds) => {
    events.forEach((event) => {
      if (event.recurringEventId) {
        // Make sure we only keep instances
        previousServerEventIds.delete(event.recurringEventId);
      }
      if (event.status === 'cancelled') {
        previousServerEventIds.delete(event.id);
      } else {
        previousServerEventIds.add(event.id);
      }
    });
    return new Set(previousServerEventIds);
  });
};

export function getGridEventIdsFamilyKey(props: {
  startAt: DateTime;
  endAt: DateTime;
  calendarId: string;
}): string {
  return props.startAt.toISODate() + props.endAt.toISODate() + props.calendarId;
}
